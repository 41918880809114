/* global GIT_COMMITHASH */

import { applyMiddleware, compose, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import { routerMiddleware } from 'react-router-redux'
import thunk from 'redux-thunk'
import { rootReducer } from './reducers'

// Sentry
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

import { datadogRum } from '@datadog/browser-rum'

const createHistory = require('history').createBrowserHistory

export const history = createHistory()
const use_sentry = ['development', 'staging', 'production'].indexOf(process.env.CONFIG_ENV) > -1

if (use_sentry) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0,
  })
}

if (process.env.USE_RUM) {
  datadogRum.init({
    applicationId: process.env.RUM_APPLICATION_ID,
    clientToken: process.env.RUM_CLIENT_TOKEN,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 0,
    trackUserInteractions: true,
    trackResources: true,
    defaultPrivacyLevel: 'mask-user-input',
    service: 'consumer-frontend',
    env: process.env.CONFIG_ENV,
    version: GIT_COMMITHASH.substring(0, 8),
  })
}


export function configureStore(initialState = {}) {
  const middlewares = [thunk, routerMiddleware(history)]
  let middleware = applyMiddleware(...middlewares)


  if (process.env.NODE_ENV === 'development') {
    middleware = composeWithDevTools(middleware)
  }

  const sentryReduxEnhancer = Sentry.createReduxEnhancer()

  const store = createStore(rootReducer, initialState, compose(middleware, sentryReduxEnhancer))

  return store
}
